/* posts index */
.post-wrap {
    margin-bottom: 60px;
}

.post-wrap h2 {
    font-family: $secondary-font;
    font-style: italic;
    font-size: 24px;
    color: #7f7f7f;
    text-align: center;
    padding: 10px 0;
    background: $gray5;
}

.post-wrap .content-wrap {
    background: $white;
    padding: 40px;
    position: relative;
    border-radius: 3px;
    margin-top: 20px;
    @include breakpoint(medium) {
        left: -40px;
    }
}

.content-wrap .title {
    position: relative;
    // border-bottom:1px solid #ddd;
    margin-bottom: 20px;
}

.content-wrap .time {
    color: $gray25;
}

.content-wrap .title h1 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;

    @include breakpoint (medium) {
        width: 475px;
    }
}

.content-wrap .title h1 a {
    color: $black;
    text-decoration: none;
}

.content-wrap .title .status {
    background: $green;
    width: 120px;
    text-align: center;
    color: $white;
    font-size: 16px;
    text-transform: uppercase;
    padding: 8px 0;
    margin: 20px 0;
    border-radius: 3px;

    @include breakpoint(medium) {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;   
    }
}

.content-wrap .title .status.in-progress {
    background: $red;
}

.content-wrap .title .status.general-notice {
    background: $yellow;
}

.content-wrap .entry-content {
    color: $gray-dark50;
    line-height: 1.65;
    font-size: 16px;

    a {
        color: $blue;
        text-decoration: none;

        &:hover {
            color: $blue-dark50;
        }
    }
}

.content-wrap .entry-content p {
    margin: 15px 0;
}
