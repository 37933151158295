body {
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 21px;
    padding: 440px 0 0;
    background: #fff;
    margin: 0;

}

.container {
    margin: 0 auto;
}
