.updates {
    border-top: 1px solid $gray15;
    padding-top: 5px;
    margin-top: 30px;
}

.updates .update {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 3px;
    color: #7f7f7f;
    line-height: 1.65;
    font-size: 16px;
    margin-top: 20px;
}

.updates h4 {
    font-size: 21px;
    color: #7f7f7f;
}

.update span {
    color: #a7a7a7;
    margin-bottom: 13px;
    display: block;
}
