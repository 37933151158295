/* BANNER */
.banner {
    position: absolute;
    top: 0;
    width: 100%;
}

.page-wrap {
    background: url("../../images/bg-page.gif") center repeat-y $gray5;
    padding: 50px 0;
}

.logo img {
    width: 135px;
    height: 38px;

    @include breakpoint (medium) {
        width: 240px;
        height: 70px;
    }
}

.banner-image,
.banner-snow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 440px;

    @include breakpoint(medium) {
        height: 469px;
    }
}

.banner-image {
    background-color: $blue;
}

.banner-snow {
    background: url("../../images/snow.gif");
    opacity: 0.2;
}

.banner .container {
    // background:url(../../images/banner-fade1.png) no-repeat center center;
    padding: 25px 0 30px;
    height: 360px;
    text-align: center;
}

.banner .container h1 {
    font-family: $primary-font;
    color: #fff;
    font-size: 36px;
    font-weight: normal;
    line-height: 12px;
    margin: 0;
    text-align: center;
}


.banner .container .logo h1 span {
    position: relative;
    font-size: 21px;
    margin-left: 5px;
    top: -11px;

    @include breakpoint(medium) {
        font-size: 34px;
        top: -22px;
        margin-left: 20px;

    }
}


.banner .container p {
}

.banner .container p {
    font-family: $secondary-font;
    font-style: italic;
    font-size: 20px;
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 26px;
    margin: 10px auto 40px;
    max-width: 17em;

    @include breakpoint(medium) {
        font-size: 28px;
        line-height: 1.5;
        text-align: center;
        margin: 30px auto 60px;
    }
}

.banner .container a {
    display: block;
    margin: 0 20px;
    padding: 13px 15px;
    text-decoration: none;
    font-size: 16px;
    color: #fff;

    @include breakpoint(medium) {
        display: inline-block;
        background: $green;
        border-radius: 3px;
        margin-right: 20px;
    }
}


.banner .container a:before {
    content: ".";
    width: 21px;
    height: 18px;
    background: url("../../images/sprite-links.png") no-repeat;
    display: inline-block;
    margin-right: 10px;
}

.banner .container .home-link {
    background: none;
    padding: none;
}

.banner .container .home-link:before {
    background: none;
    content: "";
}

.banner .container .home-link:hover {
    background: none;
}

.issue-in-progress .banner .container .rss-link,
.issue-in-progress .banner .container .twitter-link {
    background: $red;

    &:hover {
        background: $red-dark50;
    }
}

.banner .container .rss-link,
.banner .container .twitter-link {
    @include breakpoint(medium) {
        display: inline;
    }
}

.banner .container .rss-link {
    margin: 20px 20px 0;

    @include breakpoint(medium) {
        margin-right: 0;
    }
}

.banner .container .twitter-link {

    @include breakpoint(medium) {
        margin-left: -20px;
    }
}

.banner .container .rss-link:before {
    background-position: -21px 0;
}
