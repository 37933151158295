.footer {
    padding: 50px 0;
    text-align: center;
}

.footer .links {
    margin-top: 20px;
    color: #aaa;
}

.footer .links a:hover {
    color: $gray;
    text-decoration: none;
    margin: 0 5px;
}

.footer .links a {
    color: #aaa;
    text-decoration: none;
    margin: 0 5px;
}
