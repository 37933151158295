.nav-links {
    padding: 0 40px;
    position: relative;
    left: -40px;
}

.nav-links a {
    background: #fff;
    padding: 10px;
    border-radius: 3px;
    text-decoration: none;
    color: #999;
}

.nav-links a:hover {
    color: $gray-dark50;
}

.nav-links .previous a {
    position: relative;
    left: -40px;
}

.nav-links .next a {
    position: relative;
    right: -40px;
    float: right;
}
