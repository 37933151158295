/*  ==========================================================================
	Variables
============================================================================ */

/* Responsive tools
----------------------------------- */
$breakpoints: (
	xs: 40rem,			// 480px
	s: 44rem,		// 592px
	m: 65rem,		// 672px
	l: 90rem,			// 900px
	xl: 102.4rem		// 1024px
);

$max-width: 134em;
$nav-mobile-height: 6.5rem;
$nav-height: 8.5rem;
$mobile-nav-break: 769px;
$notice-height: 5rem;


/* Radius on elements
----------------------------------- */
$border-radius: 0.4rem;
$border-radius-large: 1rem;



/* Animation easing
----------------------------------- */
$ease: ease;
$ease-in: ease-in;


/* Fonts
----------------------------------- */
$primary-font: "Museo Sans Rounded";
// $secondary-font: "Archer SSm A", "Archer SSm B";
$secondary-font: "Archer SSm A", "Archer SSm B", Georgia, serif;


/* Colors
----------------------------------- */
// White
$white: #fff;

$black: #000000;

// Gray
$gray: #5d5e5e;
$gray-dark: #262727;
$gray-dark50: #434344;
$gray75: #9f9c9c;
$gray25: #c7c4c4;
$gray15: #e7e7e7;
$gray5: #f7f6f6;
$gray2: #fafafa;

// Blue
$blue: #50c6db;
$blue-dark: #01516e;
$blue-dark50: #338199;
$blue75: #b0e0ea;
$blue25: #d6eef2;

// Green
$green: #51bb7b;
$green-dark: #267048;
$green-dark50: #419564;
$green75: #add9b8;
$green25: #d4ead9;

// Yellow
$yellow: #f0ce15;
$yellow-dark: #9a7700;
$yellow-dark50: #c3a028;
$yellow75: #f6e299;
$yellow25: #f9edc9;

// Orange
$orange: #f47820;
$orange-dark: #8e4402;
$orange-dark50: #bb5f23;
$orange75: #fab889;
$orange25: #fdd9be;

// Red
$red: #ef4e65;
$red-dark: #8c2738;
$red-dark50: #ba3e51;
$red75: #f18085;
$red25: #fad1cd;

// Pink
$pink: #e0368c;
$pink-dark: #851252;
$pink-dark50: #af2a6f;
$pink75: #eba0c0;
$pink25: #f3cddd;

// Purple
$purple: #8351a0;
$purple-dark: #4e2760;
$purple-dark50: #693f7e;
$purple75: #b69cc8;
$purple25: #b69cc8;
