/* ==========================================================================
	Px to rem
========================================================================== */

@mixin font-size($pxSize: 1) {
	font-size: $pxSize + px;
	font-size: ($pxSize / 16) + rem;
}


/* ==========================================================================
	Media queries
========================================================================== */

@mixin breakpoint($size) {
	@if $size == small {
		@media (min-width: 30em) { @content; }
	}
	@else if $size == medium {
		@media (min-width: 50em) { @content; }
	}
	@else if $size == large {
		@media (min-width: 60em) { @content; }
	}
	@else if $size == xlarge {
		@media (min-width: 75em) { @content; }
	}
	@else {
		@media (min-width: $size) { @content; }
	}
}


/* ==========================================================================
	Retina media query
========================================================================== */

@mixin retina() {
	@media(min-resolution: 144dpi) { @content; }
}


/* ==========================================================================
	Clearfix
========================================================================== */

@mixin clearfix {
	&:after,
	&:before {
		display: table;
		clear: both;
		content: "";
	}
}


/* ==========================================================================
	State class
========================================================================== */

@mixin state($class) {
	&[class*='--#{$class}'] {
		@content;
	}
}
